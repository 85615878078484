<template>
  <main class="access_code">
    <b-row class="full-height">
      <b-col cols="12" md="6" class="left-side d-none d-md-block" :class="{'heaven': isHeaven}"> </b-col>
      <b-col cols="12" md="6" class="right-side" v-if="!loading">
        <section class="body">
          <h1 class="title-code">{{ $t("access_code_page.title") }}</h1>

          <div class="text-mobile">
            <p class="text">
              {{ $t("access_code_page.subtitle") }}
              {{ $t("access_code_page.expire_information") }}
            </p>
          </div>

          <div class="text-desktop">
            <p class="text">
              {{ $t("access_code_page.subtitle") }}
            </p>
            <p class="text">
              {{ $t("access_code_page.expire_information") }}
            </p>
          </div>

          <b-row class="inputs py-4 justify-content-center" cols="12">
            <b-col
              class="px-1 px-sm-2"
              v-for="(v, index) in inputs"
              :key="index"
            >
              <input
                :id="`input-${index}`"
                :name="`input-${index}`"
                @keydown.enter="confirm()"
                @keydown="(e) => nextInput(e)"
                v-model="values[index]"
                type="text"
                autocomplete="off"
                class="form-control border-primary"
                :class="{'heaven': isHeaven}"
                @paste="(e) => pasteCode(e)"
              />
            </b-col>
          </b-row>

          <Captcha
            ref="Captcha"
            @changeVerify="changeVerify"
            style="text-align: center; width: 40%"
          >
            <!-- botão para confirmação do codigo -->

            <BaseButton
              :disabled="loading"
              class="confirm"
              @click="confirm"
              variant="black"
            >
              {{ $t("access_code_page.confirm_button") }}</BaseButton
            >
            <a
              href="#"
              class="new-code"
              :class="{ disabled: code_resent }"
              @click.prevent="getNewCode"
              v-if="!loading"
              :disabled="code_resent || loading"
            >
              {{
                code_resent
                  ? counter
                  : $t("access_code_page.resent_code_button")
              }}
            </a>
          </Captcha>
        </section>
      </b-col>
      <div v-if="loading" class="loading-item">
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>
    </b-row>
  </main>
</template>

<script>
// Services
import ConfirmAccessCode from "@/services/resources/ConfirmAccessCode";
import NewAccessCode from "@/services/resources/NewAccessCode";
const serviceNewCode = NewAccessCode.build();
const serviceConfirmCode = ConfirmAccessCode.build();

// Components
import Captcha from "@/components/security/Captcha.vue";

// Methods
import { getOS, getBrowser } from "@/utils/getUserInfos";

export default {
  name: "AccessCode",
  components: {
    Captcha,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    inputs: 6,
    values: [],
    captcha: false,
    counter: 60,
    code_resent: false,
    try_counter: 0,
    maximum_attempts: 2,
    loading: false,
  }),
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    isClient() {
      return this.$store.getters["getIsClient"]
    },
  },
  methods: {
    changeVerify(e) {
      this.captcha = e;
      this.confirm("get");
    },
    async getNewCode() {
      if (!this.code_resent) {
        this.loading = true;
        await serviceNewCode
          .create({
            user_id: this.id,
            system_operational: getOS(),
            browser: getBrowser(),
          })
          .then((res) => {
            this.resentNotification(res);
            this.initResentCounter();
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        return;
      }
    },
    confirm(get) {
      this.loading = true;
      if (get !== "get") {
        this.$refs.Captcha.setCaptcha();
        this.loading = false;
        return;
      }
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          await serviceConfirmCode
            .create({
              user_id: this.id,
              access_code: this.values.join(""),
              try_counter: this.try_counter,
              captcha: this.captcha,
              system_operational: getOS(),
              browser: getBrowser(),
            })
            .then(({ access_token } = res) => {
              this.$store
                .dispatch("registerLogin", { access_token })
                .then(() => {
                  if(this.isClient) {
                    this.$router.push({ path: `/dashboard${window.location.search}`})
                  } else {
                    this.$router.push(`/verificar-email`);
                  }
                });
            })
            .catch(() => {
              this.values = [];
              this.$refs.Captcha.resetCaptcha();
            })
            .finally(() => {
              this.setTryCounter();
              this.loading = false;
            });
        }
      });
    },
    initResentCounter() {
      this.code_resent = true;
      const interval = setInterval(() => {
        this.counter--;
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        this.code_resent = false;
        this.counter = 60;
      }, 60000);
    },
    /**
     * @param {String} email_sent_to - Email that will receive the code
     */
    resentNotification({ email_sent_to }) {
      const title = this.$t("access_code_page.notification.resent.title");
      const message =
        this.$t("access_code_page.notification.resent.message") + email_sent_to;
      this.showNotification(title, message, undefined, 8000);
    },
    setTryCounter() {
      if (this.try_counter == this.maximum_attempts) {
        this.try_counter = 0;
        const title = this.$t(
          "access_code_page.notification.try_counter.title"
        );
        const message = this.$t(
          "access_code_page.notification.try_counter.message"
        );

        this.showNotification(title, message, "danger", 8000);
      } else {
        this.try_counter++;
      }
      localStorage.setItem("try_counter", this.try_counter);
    },
    /**
     * @param {String} title - Notification title
     * @param {String} message - Notification message
     * @param {String} variant - Set the notification style
     * @param {Number} delay - Defines when the notification will disappear
     */
    showNotification(
      title = "",
      message = "",
      variant = "success",
      delay = 5000
    ) {
      this.$bvToast.toast(message, {
        variant: variant,
        title: title,
        autoHideDelay: delay,
      });
    },
    nextInput(event) {
      setTimeout(() => {
        const code = event.keyCode;
        if ((code >= 48 && code <= 57) || (code >= 96 && code <= 105)) {
          let [input, id] = event.target.id.split("-");
          id = parseInt(id) + 1;
          let nextInput = document.getElementById(
            input + "-" + parseInt(id).toString()
          );
          if (nextInput) nextInput.focus();
        }
      }, 20);
    },
    pasteCode(e) {
      setTimeout(() => {
        let [_, id] = e.target.id.split("-");
        this.values = this.values[id].split("");
      }, 20);
    },
  },
  watch: {
    values: {
      deep: true,
      handler(current) {
        current.map((el, index) => {
          if (el.length > 1 && el.length < 6) {
            this.values[index] = el.toString().split("")[0];
          }
        });
      },
    },
  },
  mounted() {
    localStorage.setItem("try_counter", 0);
    this.try_counter = localStorage.getItem("try_counter");
  },
  destroyed() {
    localStorage.removeItem("try_counter");
  },
};
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.access_code {
  width: 100vw;
  height: 100vh;
}
.left-side {
  background-image: url("~@/assets/img/cadastro-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.left-side.heaven {
  background: radial-gradient(circle, $base-color-heaven-light 32%, $base-color-heaven 100%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  right: 0;
  top: 0;
  z-index: 88888;
  width: 100%;
  height: 100vh;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.left-side.heaven::after {
  content: url("~@/assets/img/icons-heaven/logo.svg");
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.right-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.body {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title-code {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 600;
}

.text {
  font-size: 14px;
  color: #81858e;
  line-height: 1.5;
}
.text-desktop {
  display: block !important;
  text-align: center;
}
.text-mobile {
  display: none !important;
  text-align: center;
}

.confirm {
  width: 100%;
  display: block;
  margin-bottom: 15px;
}
.new-code {
  font-size: 14px;
  color: #81858e;
  text-align: center;
}
.new-code:disabled,
.new-code.disabled {
  pointer-events: none !important;
  opacity: 0.7;
}

.inputs {
  max-width: 500px;
  row-gap: 10px;
  flex-wrap: nowrap;
}

input {
  text-align: center;
  width: 55px !important;
  height: 50px !important;
  padding: 5px !important;
}
.loading-item {
  padding-top: 30px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .body {
    padding: 10px 15px;
    justify-content: start;
    margin-top: 100px;
  }
  .title-code {
    text-align: center;
    font-size: 18px;
  }

  input {
    padding: 0px !important;
    width: 45px !important;
    height: 40px !important;
  }
  .text {
    font-size: 13px;
  }
  .text-desktop {
    display: none !important;
  }
  .text-mobile {
    display: block !important;
  }
  .loading-item {
    width: 100%;
  }
}
</style>
