import Rest from '@/services/Rest';

/**
 * @typedef {ConfirmAccessCode}
 */
export default class ConfirmAccessCode extends Rest {
  /**
   * @type {String}
   */
  static resource = '/confirm-access-code-login';
}
